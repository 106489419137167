import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const router = new Router({
  base: "",
  mode: "hash",
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/view/user/login.vue"),
    },
    {
      path: "/signUp",
      name: "signUp",
      component: () => import("@/view/user/sign-up.vue"),
    },
    {
      path: "/",
      name: "Main",
      component: () => import("@/view/user/main.vue"),
      redirect: "home",
      children: [
        // 首页 start
        {
          path: "home",
          name: "home",
          component: () => import("@/view/home/index.vue"),
        },
        {
          path: "notice",
          name: "notice",
          component: () => import("@/view/home/pages/notice.vue"),
        },
        {
          path: "search",
          name: "search",
          component: () => import("@/view/home/pages/search.vue"),
        },
        {
          path: "stockDetail",
          name: "stockDetail",
          component: () => import("@/components/stock-detail.vue"),
        },
        {
          path: "qip",
          name: "qip",
          component: () => import("@/view/home/pages/qip.vue"),
        },
        {
          path: "homeOrderList",
          name: "homeOrderList",
          component: () => import("@/view/home/pages/order-list.vue"),
        },
        {
          path: "ipo",
          name: "ipo",
          component: () => import("@/view/home/pages/ipo.vue"),
        },
        {
          path: "blockDeal",
          name: "blockDeal",
          component: () => import("@/view/home/pages/block-deal.vue"),
        },
        {
          path: "vipFtc",
          name: "vipFtc",
          component: () => import("@/view/home/pages/vip-ftc.vue"),
        },
        {
          path: "purchase",
          name: "purchase",
          component: () => import("@/components/purchase.vue"),
        },
        // 市场
        {
          path: "market",
          name: "market",
          component: () => import("@/view/market/index.vue"),
        },
        // 新闻
        {
          path: "news",
          name: "news",
          component: () => import("@/view/news/index.vue"),
        },
        // 投资组合
        {
          path: "portfolio",
          name: "portfolio",
          component: () => import("@/view/portfolio/index.vue"),
        },
        {
          path: "orderDetails",
          name: "orderDetails",
          component: () => import("@/view/portfolio/details.vue"),
        },
        // 个人相关
        {
          path: "profile",
          name: "profile",
          component: () => import("@/view/profile/index.vue"),
        },
        {
          path: "editPassword",
          name: "editPassword",
          component: () => import("@/view/profile/pages/edit-password.vue"),
        },
        {
          path: "recharge",
          name: "recharge",
          component: () => import("@/view/profile/pages/recharge.vue"),
        },
        {
          path: "editUser",
          name: "editUser",
          component: () => import("@/view/profile/pages/edit-user.vue"),
        },
        {
          path: "kyc",
          name: "kyc",
          component: () => import("@/view/profile/pages/kyc.vue"),
        },
        {
          path: "withdraw",
          name: "withdraw",
          component: () => import("@/view/profile/pages/withdraw.vue"),
        },
        {
          path: "bankCard",
          name: "bankCard",
          component: () => import("@/view/profile/pages/bank-card.vue"),
        },
        {
          path: "addBank",
          name: "addBank",
          component: () => import("@/view/profile/pages/add-bank.vue"),
        },
        {
          path: "editAvatar",
          name: "editAvatar",
          component: () => import("@/view/profile/pages/edit-avatar.vue"),
        },
        {
          path: "exchange",
          name: "exchange",
          component: () => import("@/view/profile/pages/exchange.vue"),
        },
        {
          path: "financialProducts",
          name: "financialProducts",
          component: () =>
            import("@/view/profile/pages/financial-products.vue"),
        },
        // 公共页面
        {
          path: "commonPage",
          name: "commonPage",
          component: () => import("@/view/common/index.vue"),
        },
        {
          path: "commonList",
          name: "commonList",
          component: () => import("@/view/common/list.vue"),
        },
        {
          path: "secure",
          name: "secure",
          component: () => import("@/view/common/secure.vue"),
        },
      ],
    },
  ],
});

export default router;
